import React, { Fragment } from "react"
import SignInForm from "../components/sign-in-form/SignInForm"
import Layout from "../components/layout"
import LayoutSimple from "../components/layoutSimple"
import SEO from "../components/seo"

const SignInPage = () => {
  React.useEffect(() => {
    document.title = "Sign in | voyayge"
  }, [])

  return (
    <Fragment>
      <SignInForm />
    </Fragment>
  )
}

export default () => (
  <LayoutSimple>
    <SEO pageTitle="Sign In" description="Sign In" />
    <SignInPage />
  </LayoutSimple>
)
